import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/EmailSignup"

export default function languages(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={"Anki"}></SEO>
      <main className="markdown">
        <h1 className="text-center font-bold text-3xl">Anki</h1>
        <p className="text-center">Articles on how to use and optimize Anki.</p>
        <h2 className="">Start Here</h2>
        <ul>
          <li>
            <Link to="/anki">A Deep Dive Into Anki (coming soon)</Link>
          </li>
        </ul>
        <h2>Anki Settings</h2>
        <ul>
          <li>
            <Link to="/anki/what-is-anki-interval-modifier">
              A Guide to Anki's Interval Modifier
            </Link>
          </li>
          <li>
            <Link to="/anki/ease-hell">Ease Hell</Link>
          </li>
        </ul>

        <br></br>
        <div className="mt-20">
          <p className="text-center font-semibold mb-3">
            I send language learning tips and updates every Saturday.
          </p>
          <EmailSignup></EmailSignup>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
